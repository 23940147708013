<template>
  <v-card
    v-if="user"
    max-width="1200"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col>
          <v-card-title class="pb-0">
            {{ title }}
            <v-spacer />
            <v-card-actions v-if="canEditUser">
              <v-spacer />
              <v-btn
                color="success"
                @click="saveChanges"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                {{ $t("Save Changes") }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row
              align="start"
              justify="start"
            >
              <v-col>
                <div class="subtitle-1">
                  <v-text-field
                    v-model="username"
                    prepend-icon="mdi-account-circle-outline"
                    :label="$t('Username')"
                    readonly
                    filled
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="email"
                    readonly
                    prepend-icon="mdi-email-outline"
                    :label="$t('Email')"
                    filled
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-badge-account-horizontal-outline"
                    :readonly="!canEditUser"
                    :label="$t('Full Name')"
                    :rules="nameRules"
                    outlined
                    dense
                  />
                </div>
              </v-col>
              <v-col>
                <div class="subtitle-1">
                  <inputTel
                    v-model="phone"
                    prepend-icon="mdi-phone-outline"
                    :readonly="!canEditUser"
                    :label="$t('Phone Number')"
                    :rules="phoneRules"
                    outlined
                    dense
                  />
                </div>
                <div
                  v-if="currentTenantId !== 'system'"
                  class="my-2 subtitle-1"
                >
                  <treeselect
                    v-if="selectedEntities.length"
                    :key="key"
                    :selected-items="selectedEntities"
                    :tree-items="entities"
                    label="Primary Group / Site"
                    :selectable="false"
                    :rules="primaryEntityRules"
                    :activatable="true"
                    :disabled="!canEditUser"
                    :dense="true"
                    prepend-icon="mdi-sitemap"
                    @select="entitySelected"
                  />
                </div>
                <div class="my-8 subtitle-1">
                  <language-input
                    v-model="locale"
                    :readonly="!canEditUser"
                    :label="$t('Language')"
                    prepend-icon="mdi-translate"
                    outlined
                    dense
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import Treeselect from '@/components/common/Treeselect.vue'
import InputTel from '@/components/common/InputTel.vue'
import LanguageInput from '@/components/common/LanguageInput.vue'
import { validateEmail } from '@/common/validations'
import { mapGetters } from 'vuex'

export default {
  name: 'InfoCard',
  components: {
    Treeselect,
    InputTel,
    LanguageInput
  },
  props: {
    username: {
      type: String,
      required: true
    },
    canEditUser: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      key: 0,
      title: '',
      email: '',
      name: '',
      phone: '',
      primaryEntity: {},
      initialPrimaryEntityId: '',
      locale: {},
      selectedEntities: [],
      user: null,
      usernameRules: [v => !!v || 'Please fill in this field'],
      emailRules: [
        v => !!v || 'Please fill in this field',
        v => validateEmail(v) || 'Please enter a valid email address'
      ],
      phoneRules: [
        v => !!v || 'You must fill in this field',
        v =>
          /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(v) ||
          'Please enter a valid phone number'
      ],
      nameRules: [v => !!v || 'Please fill in this field'],
      primaryEntityRules: [v => !!v || 'Please select a group / site']
    }
  },
  computed: {
    ...mapGetters(['locales', 'entities', 'currentTenantId'])
  },
  async created () {
    await this.getUser()
    if (this.currentTenantId === 'system') {
      this.primaryEntity = { entity: { id: 'system', tenant_id: 'system' } }
    }
  },
  methods: {
    entitySelected (value) {
      this.primaryEntity = value[0]
    },
    async getUser () {
      try {
        const data = await this.$store.dispatch('getUser', {
          username: this.username
        })
        this.user = data
        this.title = data.name
        this.name = data.name
        this.email = data.email
        this.phone = data.phone_number
        this.selectedEntities = []
        this.initialPrimaryEntityId = data['custom:primary-entity']
        if (this.currentTenantId !== 'system') {
          const entity = await this.$store.dispatch('getEntity', {
            entityId: data['custom:primary-entity'],
            tenantId: data['custom:primary-tenant']
          })
          this.primaryEntity = entity
          this.selectedEntities.push(this.primaryEntity)
        }
        this.key += 1
        this.locale = this.locales.find(el => el.value === data.locale)
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    },
    saveChanges () {
      if (!this.$refs.form.validate()) {
        return null
      }
      const primaryEntity = this.primaryEntity.entity || this.primaryEntity
      this.$store
        .dispatch('editUser', {
          username: this.username,
          attributes: [
            { name: 'email', value: this.email },
            { name: 'locale', value: this.locale ? this.locale.value : null },
            { name: 'phone_number', value: this.phone },
            { name: 'name', value: this.name },
            { name: 'custom:primary-entity', value: primaryEntity.id },
            { name: 'custom:primary-tenant', value: primaryEntity.tenant_id }
          ]
        }).then(() => {
          if (this.initialPrimaryEntityId !== primaryEntity.id) {
            this.$store.dispatch('addUserToEntity', {
              tenantId: primaryEntity.tenant_id,
              entityId: primaryEntity.id,
              role: 'EntityReader',
              username: this.username
            })
          }
        }).then(data => {
          this.$store.commit('showMessage', {
            message: `${this.title} updated successfully`,
            color: 'success'
          })
          this.$emit('modify')
          this.getUser()
        })
    }
  }
}
</script>
