
<template>
  <v-card
    max-width="1200"
    flat
    class="pa-0 ma-0"
    :loading="loading"
  >
    <v-card-title class="pb-1 text-subtitle-1 font-weight-bold">
      {{ $t("Linked Company Groups") }}
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-list-item
      v-for="(item, i) in tenantGroups"
      :key="i"
    >
      <v-list-item-content>
        <v-list-item-title>
          <strong>{{ item.entity.name }}</strong>
        </v-list-item-title>
        <v-chip-group>
          <v-chip
            v-for="(role, index) in item.explicit_roles"
            :key="index"
            :close="canEditUser"
            outlined
            pill
            @click:close="deleteRole(role, item)"
          >
            {{ role }}
          </v-chip>
        </v-chip-group>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    username: {
      type: String,
      required: true
    },
    canEditUser: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    tenantGroups: [],
    loading: true
  }),
  computed: {
  },
  created () {
    this.getTenantGroupsPerUser()
  },
  methods: {
    // canDeleteRole (item, role, entity) {
    //   if (this.isTenantGroup) { return this.canEditEntity }
    //   if (!item.explicit_roles || !item.explicit_roles.includes(role)) {
    //     return false
    //   }
    //   return this.canEditEntity
    // },
    getTenantGroupsPerUser () {
      this.loading = true
      this.$store
        .dispatch('getTenantGroupsPerUser', { username: this.username })
        .then((data) => {
          this.tenantGroups = data
          this.loading = false
        })
    },
    deleteRole (role, item) {
      const message = `Are you sure you want to delete ${role} from @${this.username}?`
      this.$confirm(message, { title: 'Delete Role from User', buttonTrueText: 'Delete', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.loading = true
            this.$store
              .dispatch('deleteRoleFromUser', {
                entityId: item.entity.id,
                role,
                username: this.username
              })
              .then(() => {
                const message = `${role} role was deleted successfully`
                this.$store.commit('showMessage', { message, color: 'success' })
                this.getTenantGroupsPerUser()
              })
              .catch(error => {
                this.$store.commit('showMessage', { message: error, color: 'error' })
              }).finally(() => { this.loading = false })
          }
        })
    }
  }
}
</script>
