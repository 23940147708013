<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            fab
            depressed
            x-small
            :disabled="disabled"
            @click="resetUsers"
          >
            <v-icon>
              mdi-key-outline
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>Force reset password</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    usernames: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    lockedUsernames: {
      type: Array,
      required: true
    }
  },
  methods: {
    async resetUsers () {
      if (!this.usernames.length) {
        return null
      }
      const lockedUsers = this.usernames.filter(el => this.lockedUsernames.includes(el))
      if (lockedUsers.length) {
        const message = `Cannot reset password to locked users: ${lockedUsers}`
        this.$store.commit('showMessage', { message: message, color: 'error' })
        return
      }

      const message = `Are you sure you want to reset password for ${this.usernames.length === 1 ? `@${this.usernames[0]}` : 'these users'}?`
      const res = await this.$confirm(message, { title: 'Reset Password', buttonTrueText: 'Reset', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
      if (!res) return

      try {
        await this.$store.dispatch('resetUsers', { usernames: this.usernames })
        await this.$store.dispatch('lockUnlockUsers', { usernames: this.usernames, action: 'disable' })
        const unlockedUsers = this.usernames.filter(el => !this.lockedUsernames.includes(el))
        await this.$store.dispatch('lockUnlockUsers', { usernames: unlockedUsers, action: 'enable' })

        this.$emit('reset')
        const message = `${this.usernames.length === 1 ? `@${this.usernames[0]}` : 'Users'} password was reset successfully`
        this.$store.commit('showMessage', { message, color: 'success' })
        await this.$store.dispatch('fetchUsers')
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    }
  }
}
</script>
