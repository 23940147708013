<template>
  <v-card
    max-width="1200"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col>
          <v-card-title class="pb-0">
            {{ title }}
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="success"
                :disabled="saveDisabled"
                @click="saveChanges"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                {{ $t('Save Changes') }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="subtitle-1">
                  <v-text-field
                    v-model="username"
                    prepend-icon="mdi-account-circle-outline"
                    :label="$t('Username')"
                    :rules="usernameRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-email-outline"
                    :label="$t('Email')"
                    :rules="emailRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-badge-account-horizontal-outline"
                    :label="$t('Full Name')"
                    :rules="nameRules"
                    outlined
                    dense
                  />
                </div>
              </v-col>
              <v-col>
                <div class="subtitle-1">
                  <inputTel
                    v-model="phone"
                    prepend-icon="mdi-phone-outline"
                    :label="$t('Phone Number')"
                    :rules="phoneRules"
                    outlined
                    dense
                  />
                </div>
                <div
                  v-if="currentTenantId !== 'system'"
                  class="my-2 subtitle-1"
                >
                  <treeselect
                    :tree-items="entities"
                    label="Primary Group / Site"
                    :hide-details="false"
                    :selectable="false"
                    :activatable="true"
                    :dense="true"
                    :rules="primaryEntityRules"
                    prepend-icon="mdi-sitemap"
                    @select="entitySelected"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <language-input
                    v-model="locale"
                    :label="$t('Language')"
                    :new-user="true"
                    :rules="localeRules"
                    prepend-icon="mdi-translate"
                    outlined
                    dense
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import Treeselect from '@/components/common/Treeselect.vue'
import InputTel from '@/components/common/InputTel.vue'
import LanguageInput from '@/components/common/LanguageInput.vue'
import { validateEmail } from '../../common/validations'
import { mapGetters } from 'vuex'

export default {
  name: 'NewUserCard',
  components: {
    Treeselect,
    InputTel,
    LanguageInput
  },
  data () {
    return {
      valid: false,
      title: 'New User',
      username: '',
      email: '',
      name: '',
      phone: '',
      primaryEntity: null,
      locale: {},
      usernameRules: [v => !!v || 'Please fill in this field'],
      emailRules: [
        v => !!v || 'Please fill in this field',
        v => validateEmail(v) || 'Please enter a valid email address'
      ],
      phoneRules: [
        v => !!v || 'You must fill in this field',
        v =>
          /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(v) ||
          'Please enter a valid phone number'
      ],
      nameRules: [v => !!v || 'Please fill in this field'],
      primaryEntityRules: [v => !!v || 'Please select a group / site'],
      localeRules: [v => Object.keys(v).length === 0 ? 'Please select a language' : v]
    }
  },
  computed: {
    ...mapGetters(['locales', 'entities', 'currentTenantId']),
    saveDisabled () {
      return false
      // return (!this.locale || !this.phone || !this.email || !this.username || !this.primaryEntity || !this.name)
    }
  },
  created () {
    if (this.currentTenantId === 'system') {
      this.primaryEntity = { entity: { id: 'system', tenant_id: 'system' } }
    }
  },
  methods: {
    entitySelected (value) {
      this.primaryEntity = value[0]
    },
    saveChanges () {
      if (!this.$refs.form.validate()) {
        return null
      }
      this.$store
        .dispatch('createUser', {
          username: this.username,
          primaryTenantId: this.primaryEntity.entity.tenant_id,
          primaryEntityId: this.primaryEntity.entity.id,
          attributes: [
            { name: 'email', value: this.email },
            { name: 'locale', value: this.locale ? this.locale.value : null },
            { name: 'phone_number', value: this.phone },
            { name: 'name', value: this.name }
          ]
        }).then(() => {
          if (this.currentTenantId !== 'system') {
            this.$store.dispatch('addUserToEntity', {
              tenantId: this.primaryEntity.entity.tenant_id,
              entityId: this.primaryEntity.entity.id,
              role: 'EntityReader',
              username: this.username.toLowerCase()
            })

            if (this.primaryEntity.entity.id !== this.primaryEntity.entity.tenant_id) {
              this.$store.dispatch('addUserToEntity', {
                tenantId: this.primaryEntity.entity.tenant_id,
                entityId: this.primaryEntity.entity.tenant_id,
                role: 'EntityReader',
                username: this.username.toLowerCase()
              })
            }
          }
        }).then((data) => {
          this.$store.commit('showMessage', { message: 'User added successfully', color: 'success' })
          this.$emit('add', this.username)
        })
        .catch((error) => {
          if (error.startsWith('UsernameExistsException: An account with the email already exists')) {
            this.$store.commit('showMessage', { message: `User with email '${this.email}' already exists`, color: 'error' })
          } else if (error.startsWith('UsernameExistsException')) {
            this.$store.commit('showMessage', { message: `User with username '${this.username}' already exists`, color: 'error' })
          } else {
            this.$store.commit('showMessage', { message: error, color: 'error' })
          }
        })
    }
  }
}
</script>
