<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            fab
            depressed
            :disabled="disabled"
            x-small
            :loading="buttonLoading"
            @click="buttonClick()"
          >
            <v-icon>
              mdi-link-variant
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>Link</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ `Link Groups / Sites` }}</span>
          </v-card-title>
          <v-card-text class="pr-16">
            <v-container pr-16>
              <v-row class="pr-10 mb-7">
                <treeselect
                  :key="key"
                  :selected-items="selectedEntities"
                  :tree-items="entitiesList"
                  :disabled="!!entity"
                  :rules="entityRules"
                  label="Selected Groups / Sites"
                  @select="entitiesSelected"
                />
              </v-row>
              <v-row class="pr-10">
                <v-autocomplete
                  v-model="selectedUsers"
                  :items="optionalUsers"
                  :disabled="selectedUsersDisabled"
                  outlined
                  :rules="userRules"
                  item-text="username"
                  label="Selected Users"
                  multiple
                />
              </v-row>
              <v-row class="pr-10">
                <v-autocomplete
                  v-model="selectedRoles"
                  :items="roles"
                  :rules="roleRules"
                  outlined
                  label="Selected Roles"
                  multiple
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!(selectedUsers.length && selectedEntities.length && selectedRoles.length)"
              :loading="saveButtonLoading"
              @click="link"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import Treeselect from '@/components/common/Treeselect.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Treeselect
  },
  props: {
    usernames: {
      type: Array,
      default: () => []
    },
    tenantId: {
      type: String,
      required: false,
      default: ''
    },
    entity: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      valid: false,
      dialog: false,
      key: 0,
      selectedUsers: [],
      selectedEntities: [],
      selectedRoles: [],
      optionalUsers: [],
      buttonLoading: false,
      saveButtonLoading: false,
      selectedUsersDisabled: false,
      userRules: [v => (v ?? []).length > 0 || 'Please select a user'],
      entityRules: [v => (v ?? []).length > 0 || 'Please select an entity'],
      roleRules: [v => (v ?? []).length > 0 || 'Please select a role']
    }
  },
  computed: {
    ...mapGetters(['users', 'roles', 'entities', 'userName', 'currentTenantId']),
    entitiesList () {
      return (this.entity) ? [this.entity] : this.entities
    },
    tenant () {
      return (this.tenantId) ? this.tenantId : this.currentTenantId
    }
  },
  methods: {
    async buttonClick (specificUserId) {
      this.buttonLoading = true
      if (specificUserId) {
        this.selectedUsers = [specificUserId]
        this.selectedUsersDisabled = true
        this.optionalUsers = this.selectedUsers
      } else if (this.usernames.length) {
        this.selectedUsers = [...this.usernames]
        this.selectedUsersDisabled = true
        this.optionalUsers = this.selectedUsers
      } else {
        this.selectedUsersDisabled = false
        if (!(this.users && this.users[this.tenant])) {
          await this.$store.dispatch('fetchUsers', this.tenant)
        }
        this.optionalUsers = (this.users[this.tenant]) ? this.users[this.tenant].filter(el => el.username !== this.userName) : []
      }

      if (this.entity) {
        this.selectedEntities.push({ entity: this.entity })
      }

      this.buttonLoading = false
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.key += 1
      this.selectedEntities = []
      this.selectedUsers = []
      this.selectedRoles = []
    },
    entitiesSelected (value) {
      this.selectedEntities = value
    },
    async link () {
      if (!this.$refs.form.validate()) {
        return null
      }
      try {
        this.saveButtonLoading = true
        console.log('[link] users:', this.selectedUsers, 'entities:', this.selectedEntities, 'roles:', this.selectedRoles)
        for (const username of this.selectedUsers) {
          for (const entity of this.selectedEntities) {
            for (const role of this.selectedRoles) {
              await this.$store.dispatch('addUserToEntity', {
                tenantId: this.tenant,
                entityId: entity.id || entity.entity.id,
                role,
                username
              })
            }
          }
        }
        this.cancel()
        this.$emit('change')
        const message = 'Changes were done successfully'
        this.$store.commit('showMessage', { message, color: 'success' })
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      } finally { this.saveButtonLoading = false }
    }
  }
}
</script>
