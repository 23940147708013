<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-btn
          fab
          depressed
          x-small
          :disabled="disabled"
          @click="deleteUsers"
        >
          <v-icon color="error">
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </div>
    </template>
    <span>Delete</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    usernames: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    deleteUsers () {
      if (!this.usernames.length) {
        return null
      }
      const message = `Are you sure you want to delete ${this.usernames.length === 1 ? `@${this.usernames[0]}` : 'these users'}? This action cannot be undone.`
      this.$confirm(message, { title: 'Delete User', buttonTrueText: 'Delete', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.$store
              .dispatch('deleteUsers', { tenantId: this.currentTenantId, usernames: this.usernames })
              .then(() => {
                this.$emit('delete')
                const message = `${this.usernames.length === 1 ? `@${this.usernames[0]}` : 'Users'} deleted successfully`
                this.$store.commit('showMessage', { message, color: 'success' })
                this.$store.dispatch('fetchUsers')
              })
              .catch(error => {
                this.$store.commit('showMessage', { message: error, color: 'error' })
              })
          }
        })
    }
  }
}
</script>
