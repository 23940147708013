<template>
  <v-menu
    v-model="menu"
    offset-y
    fixed
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="displayedSelected"
        :label="label"
        :append-icon="`mdi-menu-${menu ? 'up': 'down'}`"
        :disabled="disabled"
        :dense="dense"
        :rules="rules"
        :prepend-icon="prependIcon"
        outlined
        readonly
        :hide-details="hideDetails"
        v-on="!readonly? on: null"
        @click:append="!readonly? menu = !menu: null"
      />
    </template>
    <v-card>
      <v-container
        class="scroll-40vh"
        fluid
      >
        <div v-if="!treeItems.length">
          {{ $t('$vuetify.noDataText') }}
        </div>
        <v-treeview
          v-else
          v-model="selected"
          :active.sync="active"
          :selectable="selectable"
          :activatable="activatable"
          selection-type="independent"
          item-children="child_entities"
          item-key="entity.id"
          item-text="entity.name"
          return-object
          :items="treeItems"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'Treeselect',
  props: {
    selectable: {
      type: Boolean,
      default: true
    },
    activatable: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    treeItems: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    prependIcon: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      active: [],
      selected: [],
      menu: false
    }
  },
  computed: {
    displayedSelected () {
      return this.selected.map(el => el?.entity ? el?.entity?.name : el?.name).join(', ')
    }
  },
  watch: {
    selectedItems: {
      immediate: true,
      handler (value) {
        if (value.length) {
          this.selected = value
        }
      }
    },
    selected (value) {
      this.$emit('select', value)
    },
    active (value) {
      this.selected = value
    }
  }
}
</script>

<style scoped>
  .scroll-40vh{
    overflow-y: auto;
    max-height: 40vh;
  }
</style>
