<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            fab
            depressed
            x-small
            :disabled="disabled"
            @click="UnLockUsers"
          >
            <v-icon color="success">
              mdi-lock-open-outline
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>Unlock</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    usernames: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    UnLockUsers () {
      if (!this.usernames.length) {
        return null
      }
      const message = `Are you sure you want to unlock ${this.usernames.length === 1 ? `@${this.usernames[0]}` : 'these users'}?`
      this.$confirm(message, { title: 'Unlock User', buttonTrueText: 'Unlock', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.$store
              .dispatch('lockUnlockUsers', { usernames: this.usernames, action: 'enable' })
              .then(() => {
                const message = `${this.usernames.length === 1 ? `@${this.usernames[0]}` : 'Users'} unlocked successfully`
                this.$store.commit('showMessage', { message, color: 'success' })
              }).then(() => {
                this.$emit('fetchUsers')
              })
              .catch(error => {
                this.$store.commit('showMessage', { message: error, color: 'error' })
              })
          }
        })
    }
  }
}
</script>
