<template>
  <v-card>
    <v-card-title
      class="primary white--text headline"
    >
      <v-text-field
        v-model="search"
        class="ml-5"
        :label="$t('Search Users Directory')"
        prepend-inner-icon="mdi-feature-search-outline"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      />
    </v-card-title>
    <v-row
      class="pa-4"
      justify="space-between"
    >
      <v-col cols="4">
        <v-row
          v-if="!loading"
          no-gutters
          class="mb-4"
        >
          <v-col>
            <v-btn
              v-if="canCreateUser"
              class="ml-3"
              depressed
              small
              @click="newUser = true"
            >
              <v-icon small>
                mdi-plus
              </v-icon>
              {{ newUserText }}
            </v-btn>
          </v-col>
          <link-button
            v-if="currentTenantId !== 'system'"
            ref="linkbtn"
            class="mr-1"
            :usernames="selectedUsernames"
            :disabled="disableButton"
            @change="rolesModified()"
          />
          <lock-button
            class="mr-1"
            :usernames="selectedUsernames"
            :disabled="disableButton"
            @fetchUsers="fetchUsers()"
          />
          <unlock-button
            class="mr-1"
            :usernames="selectedUsernames"
            :disabled="disableButton"
            @fetchUsers="fetchUsers()"
          />
          <ForceResetButton
            class="mr-1"
            :usernames="selectedUsernames"
            :locked-usernames="lockedUsernames"
            :disabled="disableButton"
          />
          <v-divider
            class="ml-2 mr-2"
            vertical
          />
          <delete-button
            :usernames="selectedUsernames"
            :disabled="disableButton"
            @delete="userDeleted"
          />
        </v-row>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="tenantUsers"
          :search="search"
          :loading="loading"
          :items-per-page="15"
          sort-by="name"
          item-key="username"
          show-select
          @click:row="rowClicked"
        >
          <template v-slot:[`item.enabled`]="{ item }">
            <v-icon
              small
              :color="`${item.enabled ? 'success': 'error'}`"
            >
              {{ `mdi-lock-${item.enabled ? 'open-outline': 'outline'}` }}
            </v-icon>
          </template>
        </v-data-table>
      </v-col>

      <v-divider
        vertical
        class="ma-0 pa-0"
      />

      <v-col cols="7">
        <v-scroll-y-transition mode="out-in">
          <new-user-card
            v-if="newUser"
            @add="newUserAdded($event)"
          />
          <div
            v-else-if="!active"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center;"
          >
            {{ $t("Select a User") }}
          </div>
          <v-card
            v-else
            :key="active.username"
            class="mx-auto"
            max-width="1200"
            flat
          >
            <v-card-text>
              <info-card
                :username="active.username"
                :can-edit-user="canEditUser"
                @delete="userDeleted"
                @modify="rolesModified"
              />
              <LinkedTenantGroups
                v-if="currentTenantId === 'system'"
                :username="active.username"
                :can-edit-user="canEditUser"
              />
              <linked-sites
                v-else
                ref="links"
                :username="active.username"
                :can-edit-user="canEditUser"
              />
            </v-card-text>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import NewUserCard from '@/components/users/UserCardNew.vue'
import InfoCard from '@/components/users/UserCardInfo.vue'
import LinkedSites from '@/components/users/UserDirectoryLinkedSites.vue'
import LinkedTenantGroups from '@/components/tenants/TenantLinkedTenantGroups.vue'
import DeleteButton from '@/components/users/UserButtonDelete.vue'
import LinkButton from '@/components/users/UserButtonLink.vue'
import LockButton from '@/components/users/UserButtonLock.vue'
import UnlockButton from '@/components/users/UserButtonUnlock.vue'
import ForceResetButton from '@/components/users/UserButtonForceReset.vue'
export default {
  components: {
    InfoCard,
    NewUserCard,
    LinkedSites,
    LinkedTenantGroups,
    DeleteButton,
    LinkButton,
    LockButton,
    UnlockButton,
    ForceResetButton
  },
  data: () => ({
    selected: [],
    caseSensitive: false,
    search: null,
    newUser: false,
    active: null,
    loading: true,
    canCreateUser: false,
    readOnlyUsersOpt: false,
    allUsersOps: false,
    tenantUsers: [],
    isSystemAdmin: false,
    canEditUser: true, // FIXME
    fields: [
      { value: 'name', text: 'Name' },
      { value: 'username', text: 'Username' },
      { value: 'enabled', text: 'Status' }
    ]
  }),
  computed: {
    ...mapGetters(['users', 'readOnlyUsers', 'allUsers', 'userName', 'currentTenantId', 'tenantsList']),
    disableButton () {
      return !this.selected.length || !!this.selected.find(el => el.username === this.userName)
    },
    selectedUsernames () {
      return this.selected.map(el => el.username)
    },
    lockedUsernames () {
      return this.selected.filter(el => el.enabled === false).map(el => el.username)
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    headers () {
      var headerFields = [...this.fields]
      if (this.allUsersOps) {
        headerFields.push({ value: 'company', text: 'Company' })
      }

      const headers = headerFields.map(el => {
        return {
          ...el,
          sortable: false,
          text: this.$t(`${el.text}`)
        }
      })
      return headers
    },
    newUserText () {
      return (this.currentTenantId === 'system') ? this.$t('New System User') : this.$t('New User')
    }
  },
  watch: {
    currentTenantId: {
      immediate: false,
      handler () {
        this.getData()
        if (!this.canCreateUser) { this.$router.push('/') }
        this.newUser = false
        this.active = null
        this.selected = []
      }
    }
  },
  async created () {
    await this.getData()
    if (!this.canCreateUser) { this.$router.push('/') }
  },
  methods: {
    async setCanCreateUsers () {
      var usersCreate = await this.$store.dispatch('enforce',
        {
          userName: this.userName,
          tenantId: this.currentTenantId,
          action: 'users-create',
          entity: this.currentTenantId
        })
      if (usersCreate) {
        this.canCreateUser = true
        return
      }
      var userEntities = await this.$store.dispatch('getEntitiesPerUser', { username: this.userName })
      var containsEntityAdmin = userEntities.map(el => el.roles).some(el => el.includes('EntityAdmin'))
      if (containsEntityAdmin) {
        this.canCreateUser = true
      }
    },
    async rolesModified () {
      await this.fetchUsers()
      if (this.active) {
        this.$refs.links.getEntitiesPerUser()
      }
    },
    async getData () {
      this.loading = true
      await this.setPermissions()
      await this.fetchUsers()
      this.loading = false
    },
    async setPermissions () {
      this.loading = true
      await this.setCanCreateUsers()
      this.isSystemAdmin = await this.$store.dispatch('enforce',
        {
          userName: this.userName,
          tenantId: 'system',
          action: 'users-edit',
          entity: 'user-registry'
        })
      this.loading = false
    },
    tenantName (tenantId) {
      if (!tenantId) return 'NONE'
      const tenant = this.tenantsList.find(el => el.id === tenantId)
      if (!tenant) return 'NONE'
      return tenant.name
    },
    async fetchUsers () {
      this.loading = true
      this.allUsersOps = this.isSystemAdmin && this.currentTenantId === 'system'
      const entities = await this.$store.dispatch('getEntities', { skipRoles: true })
      this.$store.commit('setEntities', entities || [])
      if (this.allUsersOps) {
        await this.$store.dispatch('fetchAllUsers')
        this.tenantUsers = this.allUsers.filter(el => (!!el))
        this.tenantUsers.map(el => { el.company = this.tenantName(el['custom:primary-tenant']) })
      } else {
        await this.$store.dispatch('fetchUsers')
        this.tenantUsers = (this.readOnlyUsersOpt) ? this.readOnlyUsers[this.currentTenantId] : this.users[this.currentTenantId]
      }
      this.loading = false
    },
    userDeleted () {
      this.active = null
      this.selected = []
      this.fetchUsers()
    },
    newUserAdded (id) {
      this.fetchUsers()
      this.newUser = false
      this.active = null
      if (this.currentTenantId !== 'system') this.$refs.linkbtn.buttonClick(id)
    },
    rowClicked (value) {
      this.active = value
      this.selected = [value]
      this.newUser = false
    }
  }
}
</script>
